import React, { useState, useRef, useEffect } from "react";
import LogoIcon from "../components/logo/icon";

function Terms() {
  useEffect(() => {
    document.title = `KrasniyLand Terms and Conditions`;
  }, []);

  return (
    <>
      <div
        className="relative "
        style={{ background: "linear-gradient(0, #fff, #0874d9)" }}
      >
        <div className="relative z-10 ">
          <div className="max-w-5xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <div className="w-16 p-2">
              <LogoIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-5xl shadow-xl rounded-xl bg-white mx-auto  px-4 py-12 sm:px-6   lg:px-8  text-gray-800 mb-12">
        <h1 className="mt-4 font-semibold text-3xl">END USER LICENSE AGREEMENT</h1>
         
        <div className="py-4">
          <h2 className="mt-4 font-semibold text-lg text-gray-900">
          Who May Use the Service 
          </h2>
 
          <p>KrasniyLand is licensed to You (End-User) by KrasniyLand for use only under the terms of this License Agreement.</p>

          <p>By downloading the Application from the Apple AppStore, and any update thereto (as permitted by this License Agreement), You indicate that You agree to be bound by all of the terms and conditions of this License Agreement, and that You accept this License Agreement. </p>

          <p>The parties of this License Agreement acknowledge that Apple is not a Party to this License Agreement and is not bound by any provisions or obligations with regard to the Application, such as warranty, liability, maintenance and support thereof. KrasniyLand, not Apple, is solely responsible for the licensed Application and the content thereof. </p>

          <p>This License Agreement may not provide for usage rules for the Application that are in conflict with the latest App Store Terms of Service. KrasniyLand acknowledges that it had the opportunity to review said terms and this License Agreement is not conflicting with them. </p>

          <p>All rights not expressly granted to You are reserved. </p>

          <h2 className="mt-4 font-semibold text-lg text-gray-900">Privacy</h2>

          <p>Our Privacy Policy (https://www.krasniyland.com/privacy-policy) describes how we handle the information you provide to us when you use our Service. You understand that through your use of the Service you consent to the collection and use (as set forth in the Privacy Policy) of this information, including the transfer of this information to the United States for storage, processing and use by KrasniyLand.</p>


          <h2 className="mt-4 font-semibold text-lg text-gray-900">KrasniyLand Rules</h2>

   <p>KrasniyLand's purpose is to serve the public conversation. We have a zero tolereance policy for violence, harassment and other objectionable content or abusive users and reserve the right to eject users who provide offensive or abusive content.</p>


          <h2 className="mt-4 font-semibold text-lg text-gray-900">
          1. THE APPLICATION  </h2>

          <p>KrasniyLand (hereinafter: Application) is a piece of software created to Communicate, chat and engage with friends - and customized for Apple mobile devices. It is used to Follow friends, post status updates and interact with posts with likes and comments.</p>
        
          <h2 className="mt-4 font-semibold text-lg text-gray-900">2. SCOPE OF LICENSE</h2>


      

<p>2.1  You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any Apple-branded Products that You (End-User) own or control and as permitted by the Usage Rules set forth in this section and the App Store Terms of Service, with the exception that such licensed Application may be accessed and used by other accounts associated with You (End-User, The Purchaser) via Family Sharing or volume purchasing.</p>
 
<p>2.2  This license will also govern any updates of the Application provided by Licensor that replace, repair, and/or supplement the first Application, unless a separate license is provided for such update in which case the terms of that new license will govern.</p>
 
<p>2.3  You may not share or make the Application available to third parties (unless to the degree allowed by the Apple Terms and Conditions, and with KrasniyLand's prior written consent), sell, rent, lend, lease or otherwise redistribute the Application.</p>
 
<p>2.4  You may not reverse engineer, translate, disassemble, integrate, decompile, integrate, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Application, or any part thereof (except with KrasniyLand's prior written consent).</p>
 
<p>2.5  You may not copy (excluding when expressly authorized by this license and the Usage Rules) or alter the Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this license, the App Store Terms of Service, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time.</p>
 
<p>2.6  Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.</p>
 
<p>2.7  Licensor reserves the right to modify the terms and conditions of licensing.</p>
 
<p>2.8  Nothing in this license should be interpreted to restrict third-party terms. When using the Application, You must ensure that You comply with applicable third-party terms and conditions.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">3. TECHNICAL REQUIREMENTS</h2>

<p>3.1  Licensor attempts to keep the Application updated so that it complies with modified/new versions of the firmware and new hardware. You are not granted rights to claim such an update.</p>
<p>3.2  You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Application satisfies the technical  specifications mentioned above.</p>
<p>3.3  Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">4. MAINTENANCE AND SUPPORT</h2>

<p>4.1  The Licensor is solely responsible for providing any maintenance and support services for this licensed Application. You can reach the Licensor at the email address listed in the App Store Overview for this licensed Application.</p>
<p>4.2  KrasniyLand and the End-User acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the licensed Application.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">5. USE OF DATA</h2>

<p>You acknowledge that Licensor will be able to access and adjust Your downloaded licensed Application content and Your personal information, and that Licensor's use of such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy: https://www.krasniyland.com/privacy-policy.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">6. LIABILITY</h2>

<p>6.1  Licensor's responsibility in the case of violation of obligations and tort shall be limited to intent and gross negligence. Only in case of a breach of essential contractual duties (cardinal obligations), Licensor shall also be liable in case of slight negligence. In any case, liability shall be limited to the foreseeable, contractually typical damages. The limitation mentioned above does not apply to injuries to life, limb, or health.</p>
<p>6.2  Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of this Agreement. To avoid data loss, You are required to make use of backup functions of the Application to the extent allowed by applicable third-party terms and conditions of use. You are aware that in case of alterations or manipulations of the Application, You will not have access to licensed Application.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">7. WARRANTY</h2>

<p>7.1  Licensor warrants that the Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the Application works as described in the user documentation.</p>
<p>7.2  No warranty is provided for the Application that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of KrasniyLand's sphere of influence that affect the executability of the Application.</p>
<p>7.3  You are required to inspect the Application immediately after installing it and notify KrasniyLand about issues discovered without delay by e-mail provided in Product Claims. The defect report will be taken into consideration and further investigated if it has been mailed within a period of thirty (30) days after discovery.</p>
<p>7.4  If we confirm that the Application is defective, KrasniyLand reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.</p>
<p>7.5  In the event of any failure of the Application to conform to any applicable warranty, You may notify the App-Store-Operator, and Your Application purchase price will be refunded to You. To the maximum extent permitted by applicable law, the App-Store-Operator will have no other warranty obligation whatsoever with respect to the App, and any other losses, claims, damages, liabilities, expenses and costs attributable to any negligence to adhere to any warranty.</p>
<p>7.6  If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the Application was made available to the user. The statutory periods of limitation given by law apply for users who are consumers.</p>

<h2 className="mt-4 font-semibold text-lg text-gray-900">8. PRODUCT CLAIMS</h2>

<p>KrasniyLand and the End-User acknowledge that KrasniyLand, and not Apple, is responsible for addressing any claims of the End-User or any third party relating to the licensed Application or the End-User’s possession and/or use of that licensed Application, including, but not limited to:</p>

<p>(i) product liability claims;</p>
<p>(ii) any claim that the licensed Application fails to conform to any applicable legal or regulatory requirement; and</p>
<p>(iii) claims arising under consumer protection, privacy, or similar legislation, including in connection with Your Licensed Application’s use of the HealthKit and HomeKit.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">9. LEGAL COMPLIANCE</h2>

<p>You represent and warrant that You are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and that You are not listed on any U.S. Government list of prohibited or restricted parties.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">10. CONTACT INFORMATION</h2>                  

<p>For general inquiries, complaints, questions or claims concerning the licensed Application, please contact:</p>
     
<p>David Krasniy </p>
<p>support@krasniyland.com</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">11. TERMINATION</h2>

<p>The license is valid until terminated by KrasniyLand or by You. Your rights under this license will terminate automatically and without notice from KrasniyLand if You fail to adhere to any term(s) of this license. Upon License termination, You shall stop all use of the Application, and destroy all copies, full or partial, of the Application.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">12. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</h2>

<p>KrasniyLand represents and warrants that KrasniyLand will comply with applicable third-party terms of agreement when using licensed Application.</p>
<p>In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," Apple and Apple's subsidiaries shall be third-party beneficiaries of this End User License Agreement and - upon Your acceptance of the terms and conditions of this license agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">13. INTELLECTUAL PROPERTY RIGHTS</h2>

<p>KrasniyLand and the End-User acknowledge that, in the event of any third-party claim that the licensed Application or the End-User's possession and use of that licensed Application infringes on the third party's intellectual property rights, KrasniyLand, and not Apple, will be solely responsible for the investigation, defense, settlement and discharge or any such intellectual property infringement claims.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">14. APPLICABLE LAW</h2>

<p>This license agreement is governed by the laws of the State of California excluding its conflicts of law rules.</p>


<h2 className="mt-4 font-semibold text-lg text-gray-900">15. MISCELLANEOUS</h2>

<p>15.1  If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.</p>
<p>15.2  Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.</p>

          <p>Date of Last Revision: July 29, 2020</p>
        </div>
      </div>
    </>
  );
}

export default Terms;
