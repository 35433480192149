import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import client from "./utils/apolloClient";
import { BrowserRouter } from "react-router-dom";
import Router from './components/router/index'
import "./styles/main.css"

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);