import React, { useState, useRef, useEffect } from "react";
import LogoIcon from "../components/logo/icon";

function Support() {
  useEffect(() => {
    document.title = `KrasniyLand Support`;
  }, []);

  return (
    <>
      <div
        className="relative "
        style={{ background: "linear-gradient(0, #fff, #0874d9)" }}
      >
        <div className="relative z-10 ">
          <div className="max-w-5xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <div className="w-16 p-2">
              <LogoIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-5xl shadow-xl rounded-xl bg-white mx-auto  px-4 py-12 sm:px-6   lg:px-8  text-gray-800 mb-12">
        <h1 className="font-semibold text-3xl">Support</h1>
        <p className="leading-6">
        For support, please reach out to us at <span className="font-semibold">support@krasniyland.com</span>
        </p>
         
      </div>
    </>
  );
}

export default Support;
