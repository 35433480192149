import React, { useState, useEffect } from 'react'
import TimeAgo from 'react-timeago'
import {NavLink} from 'react-router-dom'
import axios from "axios";
import {  config } from "../utils/api";
import DropDownButton from './dropdown-button'


const reactStringReplace = require('react-string-replace');



const SinglePost = ({me, authorUsername,id, item, verified, firstName, lastName, date, postType, postContent, comments,likes, refetchPost}) => {
 
    const [postLikedByMe, setPostLikedByMe] = useState(false)
    const [postLikeCount, setPostLikeCount] = useState(0)

    const [viewingComments, setViewingComments] = useState(false)


    const [commentsData, setCommentsData] = useState([])
  

      useEffect(() => {
        axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT}/comments?post=${id}`,
          config
        )
        .then((response) => {
          // Handle success.  
          setCommentsData(response.data)
        })
        .catch((error) => {
          console.log("An error occurred:", error.response);
        });
    }, [viewingComments]);





    useEffect(() => {
        setPostLikeCount(likes.length)
        if(likes.length > 0){
            likes.forEach(element => {  
                if(element.liked_by == me) {
    
                    setPostLikedByMe(true)
                }
            });
        }
       
    }, [likes]);


    function toggleLikePost(){
     

        if(postLikedByMe) {
            axios
            .get(
              `${process.env.REACT_APP_API_ENDPOINT}/likes?post=${id}&liked_by=${me}`,
              config
            )
            .then((response) => {
              //use info from record to delete it 
      
              axios
              .delete(
                `${process.env.REACT_APP_API_ENDPOINT}/likes/${response.data[0].id}`,
                config
              )
              .then((response) => { 
                setPostLikedByMe(false)
                setPostLikeCount(postLikeCount-1)
            })
              .catch((error) => {
                console.log("An error occurred:", error.response);
              });
               
            })
            .catch((error) => {
              console.log("An error occurred:", error.response);
            });
        } else {
            const body = {
                post: id, 
              };
              axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/likes`, body, config)
                .then((response) => {
             
                 
                 
                  setPostLikedByMe(true)
                  setPostLikeCount(postLikeCount+1)

                })
                .catch((error) => {
                  console.log("An error occurred:", error.response);
                });
        } 
    }


  
    
    return (
        <> 
        <div className="bg-gray-100 rounded-xl my-4 p-6 relative">
         
          
            <div  className=" flex flex-wrap items-center hover:text-blue-600  mb-2" >
                <NavLink className="font-semibold text-gray-900 hover:text-blue-600" to={`/u/${authorUsername}`}>
                    <span className="mr-1  flex items-center">{firstName + ' ' + lastName}  {verified ? <svg className="-mt-1 h-4 ml-1 text-blue-500 w-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="badge-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 256c0-37.7-23.7-69.9-57.1-82.4 14.7-32.4 8.8-71.9-17.9-98.6-26.7-26.7-66.2-32.6-98.6-17.9C325.9 23.7 293.7 0 256 0s-69.9 23.7-82.4 57.1c-32.4-14.7-72-8.8-98.6 17.9-26.7 26.7-32.6 66.2-17.9 98.6C23.7 186.1 0 218.3 0 256s23.7 69.9 57.1 82.4c-14.7 32.4-8.8 72 17.9 98.6 26.6 26.6 66.1 32.7 98.6 17.9 12.5 33.3 44.7 57.1 82.4 57.1s69.9-23.7 82.4-57.1c32.6 14.8 72 8.7 98.6-17.9 26.7-26.7 32.6-66.2 17.9-98.6 33.4-12.5 57.1-44.7 57.1-82.4zm-144.8-44.25L236.16 341.74c-4.31 4.28-11.28 4.25-15.55-.06l-75.72-76.33c-4.28-4.31-4.25-11.28.06-15.56l26.03-25.82c4.31-4.28 11.28-4.25 15.56.06l42.15 42.49 97.2-96.42c4.31-4.28 11.28-4.25 15.55.06l25.82 26.03c4.28 4.32 4.26 11.29-.06 15.56z" class=""></path></svg> : ""}</span>
                    </NavLink>
                    <span className="text-gray-600 font-normal text-sm">@{authorUsername} - <TimeAgo date={date} /></span>
           
                </div>
                {reactStringReplace(postContent, /@(\w+)/g, (match, i) => (
                <NavLink key={match + i} className="text-blue-600" to={`/u/${match}`}>@{match}</NavLink>
                ))}
            <div className="-m-6 bg-gray-200 flex items-center justify-between mt-6 p-2 rounded-t-none rounded-xl">
                <div><button type="button" onClick={()=>toggleLikePost()} className={`flex  mr-2 items-center  rounded-xl p-2 px-4  text-sm focus:outline-none font-semibold ${postLikedByMe ? 'text-red-600 bg-white' : ''}`}><svg className={`w-4 h-4 mr-1 ${postLikedByMe ? ' text-red-600' : ' text-gray-600'}`}  fill={`${postLikedByMe ? '#E02200' : 'none'}`} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg> {postLikeCount}</button>
                {/* <button type="button" onClick={()=>setViewingComments(true)}className="flex items-center bg-gray-200 rounded-xl p-2 px-4  text-sm focus:outline-none font-semibold"><svg  className="w-4 h-4   text-gray-600 mr-1"  fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg> {comments.length}</button>
                */}
                </div>
             

                  </div>
        </div>

        <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center pointer-events-none z-40">
            

        <div     className={`fixed inset-0 transition-opacity  ${viewingComments ? 'opacity-100 ease-in duration-200' : 'opacity-0 pointer-events-none ease-out duration-300'}`}>
      <div class="absolute inset-0 bg-gray-700 opacity-75"></div>
            </div>


        <div className={`bg-white rounded-xl px-4 pt-5 pb-4 z-30 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 ${viewingComments ? 'opacity-100 translate-y-0 sm:scale-100 pointer-events-auto' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 ease-out duration-300'}`}>{comments.map((item,i)=>{
            return (
            <div className="text-base">@: {item.comment}</div>
            )
        })}</div>

</div>
 
        </>
    )
}

export default SinglePost