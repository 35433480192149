import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import { token, config } from "../../utils/api";
import SinglePost from "../../components/post";
import { NavLink } from "react-router-dom";

const UserProfile = ({ match, location }) => {
  const {
    params: { username },
  } = match;

  const [loading, setLoading] = useState(true);

  const [me, setMe] = useState();
  const [user, setUser] = useState([]);

  const [userPosts, setUserPosts] = useState([]);
  const [userPostsCount, setUserPostsCount] = useState(0);

  const [userPostsTotalLikes, setUserPostsTotalLikes] = useState(null);


  const [userFollowingCount, setUserFollowingCount] = useState(0);
  const [userFollowersCount, setUserFollowersCount] = useState(0);

  const [meFollowingList, setMeFollowingList] = useState([]);

  useEffect(() => {
    getUser();
    getMe();
 
 


  }, [username]);

 


  async function getMe() {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, config)
      .then((response) => {
        // Handle success.
        setMe(response.data);
        getMeFollowingList(response.data.id);
      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }
  async function getUser() {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/users?username=${username}`,
        config
      )
      .then((response) => {
        // Handle success.
        setUser(response.data[0]);

        //fetch their posts
        getUserPosts(response.data[0].id);
        getUserFollowers(response.data[0].id);
        getUserFollowing(response.data[0].id);
      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }

  function getUserPosts(u) {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/posts?user.id=${u}`, config)
      .then((response) => {
        // Handle success.
        let totalLikesCount = 0

        for(let i = 0; i < response.data.length; i++) {
         totalLikesCount += response.data[i].likes.length
        }
        setUserPostsTotalLikes(totalLikesCount)
        setUserPostsCount(response.data.length);
        setUserPosts(response.data);
      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }

  async function getUserFollowers(id) {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/followings/count?following_user=${id}`,
        config
      )
      .then((response) => {
        // Handle success.
        setUserFollowersCount(response.data);
      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }

  async function getUserFollowing(id) {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/followings/count?user=${id}`,
        config
      )
      .then((response) => {
        // Handle success.
        setUserFollowingCount(response.data);
      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }

  function getMeFollowingList(id) {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/followings?user=${id}`,
        config
      )
      .then((response) => {
        
        // Handle success.
        let listOfFollowingUsers  = [];
        let listOfFollowingUsersIDS  = [];

        
        response.data.forEach(function (followingUser) {
       
          listOfFollowingUsers.push(followingUser)
        });

        for(let i = 0; i < listOfFollowingUsers.length; i++){
         if(listOfFollowingUsers[i].following_user && listOfFollowingUsers[i].following_user.id) {
         
          listOfFollowingUsersIDS.push(listOfFollowingUsers[i].following_user.id)
         }
        }
       
        setMeFollowingList(listOfFollowingUsersIDS);

        setLoading(false)
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  }


  function FollowUser(idToFollow) {
    const body = {
      following_user: idToFollow,
      following_user_id: idToFollow,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/followings`, body, config)
      .then((response) => {
        getMe();
        getUser();
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  }

  function unfollowUser(me,user) {
    //find the record with the relationship
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/followings?user=${me}&following_user.id=${user}`,
        config
      )
      .then((response) => {
        //use info from record to delete it 

        axios
        .delete(
          `${process.env.REACT_APP_API_ENDPOINT}/followings/${response.data[0].id}`,
          config
        )
        .then((response) => { 
          console.log("UNFOLLOWED!")
          getMe();
          getUser();
         })
        .catch((error) => {
          console.log("An error occurred:", error.response);
        });
         
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });

 
  }
 

  function blockUser(idToBlock) {
    if(user.viewerBlockingProfile) {
        console.log("UNBLOCK")
    } else {
      const body = {
        blocked_user: idToBlock, 
      };
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/blocks`, body, config)
        .then((response) => {
          
          getMe();
          getUser();
        })
        .catch((error) => {
          console.log("An error occurred:", error.response);
        });
    }
    
  }


  

  const FollowUnfollowButton = ({ viewingProfileId }) => {
    let isFollowingUser = meFollowingList.includes(viewingProfileId); 
    if (isFollowingUser) {
      return <button type="button" className="border rounded-full p-2 px-3"  onClick={()=>unfollowUser(me.id, user.id)}>Unfollow</button>;
    } else {
    return <button type="button" className="border rounded-full p-2 px-3 bg-blue-600 text-white" onClick={()=>FollowUser(viewingProfileId)}>Follow</button>;
    }
  };

  const BlockUnBlockButton = ({viewingProfileId}) => {
    
  return (<button type="button" className="ml-1 border rounded-full p-2 px-3 bg-gray-200 text-gray-600" onClick={()=>blockUser(viewingProfileId)}>{user.viewerBlockingProfile ? 'Blocked' : 'Block'}</button>);
    } 
 

  return (
    <div className="App">
      <Layout>
      <div className="py-5 px-4 sm:px-6 lg:px-8 bg-white max-w-6xl">
      {loading ? 'loading': <>
<div className="flex justify-between">

  <div>
  <div className="shadow-lg h-16 w-16 rounded-full">
          {user.avatar && user.avatar.url ?   <img className="inline-block h-16 w-16 rounded-full" src={`${process.env.REACT_APP_API_ENDPOINT}${user.avatar && user.avatar.url}`} alt={user.first_name + ' Profile Image'}/>
             
                : <svg className="inline-block h-16 w-16 rounded-full bg-gray-100 text-gray-800" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path>
              </svg>}</div>


          <h1 className="font-semibold text-2xl flex items-center">
            {user.first_name} {user.last_name} {user.verified ? <svg className="text-blue-500 w-6 h-6 ml-1 -mt-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="badge-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 256c0-37.7-23.7-69.9-57.1-82.4 14.7-32.4 8.8-71.9-17.9-98.6-26.7-26.7-66.2-32.6-98.6-17.9C325.9 23.7 293.7 0 256 0s-69.9 23.7-82.4 57.1c-32.4-14.7-72-8.8-98.6 17.9-26.7 26.7-32.6 66.2-17.9 98.6C23.7 186.1 0 218.3 0 256s23.7 69.9 57.1 82.4c-14.7 32.4-8.8 72 17.9 98.6 26.6 26.6 66.1 32.7 98.6 17.9 12.5 33.3 44.7 57.1 82.4 57.1s69.9-23.7 82.4-57.1c32.6 14.8 72 8.7 98.6-17.9 26.7-26.7 32.6-66.2 17.9-98.6 33.4-12.5 57.1-44.7 57.1-82.4zm-144.8-44.25L236.16 341.74c-4.31 4.28-11.28 4.25-15.55-.06l-75.72-76.33c-4.28-4.31-4.25-11.28.06-15.56l26.03-25.82c4.31-4.28 11.28-4.25 15.56.06l42.15 42.49 97.2-96.42c4.31-4.28 11.28-4.25 15.55.06l25.82 26.03c4.28 4.32 4.26 11.29-.06 15.56z" class=""></path></svg> : ""}
          </h1>
          <p>@{user.username}</p>
    </div>
    <div>
          
    {me && parseInt(me.id) == parseInt(user.id) ? (
            <NavLink
              to={"/settings/profile"}
              className="inline-block my-4 rounded-full border p-2 px-3"
            >
              Edit profile
            </NavLink>
          ) : (<>
            <FollowUnfollowButton viewingProfileId={user.id} />
            {/* <BlockUnBlockButton  viewingProfileId={user.id} /> */}
            
            </>
          )}
    </div>
</div>
      
     

          <div className="  border-t border-b py-4 my-4 flex justify-between">
          <NavLink to={`/u/${user.username}/followers`}>
            {userFollowersCount} Followers
          </NavLink>
          
          <NavLink to={`/u/${user.username}/following`}>
            {userFollowingCount} Following
          </NavLink>

          {userPostsTotalLikes ? <div className="flex items-center"><svg className={`w-4 h-4 mr-1   text-gray-600`}  fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg> {userPostsTotalLikes} likes</div> : '0 likess'}
          </div>
     

     
       
{me && parseInt(me.id) == parseInt(user.id) ? (
<NavLink
              to={"/logout"}
              className="inline-block my-4 rounded-full border-2 border-red-500  text-red-600 text-sm font-semibold p-2 px-3"
            >
              Sign Out
            </NavLink>) : null}

            <p className="text-center text-gray-600">{userPostsCount} Posts</p>
          {userPosts
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .map((item, i) => {
              return (
                <SinglePost
                  key={i}
                  me={me.id}
                  id={item.id}
                  item={item}
                  authorUsername={user.username}
                  verified={user.verified} 

                  firstName={user.first_name}
                  lastName={user.last_name}
                  date={item.created_at}
                  postType={item.post_type}
                  postContent={item.post_content}

                  comments={item.comments}
                  likes={item.likes}
                  
                />
              );
            })}</>}
          
        </div>
      </Layout>
    </div>
  );
};

export default UserProfile;
