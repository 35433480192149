import React, {useState, useEffect} from "react";
import Layout from "../../components/layout";
import { NavLink } from "react-router-dom";
import LogoIcon from '../../components/logo/icon.js'
import axios from "axios";


function PublicHomePage() {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      // Update the document title using the browser API
     reportPost()
      console.log(JSON.stringify(navigator && navigator.userAgent))
      console.log(JSON.stringify(navigator && navigator.userAgentData))
    });


 
  function reportPost(postId, type, desc) {
    const body = {
 
      type: 'event',
      details: `pageview visit: REFER:${document && document.referrer} DEVICE: ${JSON.stringify(navigator && navigator.userAgent)}`,
    };
    axios
      .post(`https://api.krasniyland.com/pageviews`, body)
      .then((response) => {
       console.log("event logged")
      })
      .catch((error) => {
        console.log('An error occurred:', error.response);
      });
  }


  return (
    <div className="h-screen flex flex-col justify-center" style={{'background':'linear-gradient(0, #fff, #fff,#0874d9)'}}>
      <div className="max-w-lg mx-auto px-8">

        
          <div className="w-32 p-2 text-center mx-auto -mt-20">
           <NavLink to={'/'}><LogoIcon/></NavLink>
          </div>
          <h1 className="text-2xl font-semibold text-center py-2 pb-1">Welcome to KrasniyLand</h1>
          <p className="text-center text-gray-700">If you've previously made an account on the web site, you can use the same login credentials in the iOS app.</p>

      <a href={'itms-apps://apps.apple.com/us/app/id1525660519'} className="justify-center m-6  rounded-full py-3 px-12 bg-blue-600 text-white font-semibold text-center flex items-center text-sm">

        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-5 -mt-1 h-5 mr-1 svg-inline--fa fa-apple fa-w-12 fa-2x"><path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" class=""></path></svg> <span>Download on App Store</span></a>
        <p className="text-center text-gray-500">Contact us <a href="mailto:support@krasniyland.com">support@krasniyland.com</a></p>
        <p className="text-center text-gray-500 text-xs mt-4">Note: no new user registrations are being accepted at this time.</p>

      </div>
      {/* <NavLink to={'/register'} className="block m-6 border rounded-full p-2 px-3 bg-gray-200 text-gray-700 font-semibold text-center">Create an account</NavLink> */}
    </div>
  );
}

export default PublicHomePage;
