import React,{useState, useEffect} from "react";
import axios from "axios";  
import Layout from "../../components/layout";
import {token, config} from '../../utils/api'
import SinglePost from '../../components/post'
import { NavLink } from "react-router-dom";


const EditProfile = ({ match, location }) => {
 
  const {
    params: { username },
  } = match;


  const [loading, setLoading] = useState(''); 
 
 
  const [user,setUser] = useState([]) 
  const [file,setFile] = useState() 

 

  useEffect(() => {
    getUser(); 
 
  }, []);



  async function getUser() {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`,config)
      .then((response) => {
        setUser(response.data)
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  }
 

  function updateProfile(){
    const body = {
      username: 'david', 
    };

    axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/users/${user.id}`, body, config)
    .then((response) => {
     console.log("SUCCESS RESPONSE", response)
    })
    .catch((error) => {
      console.log("An error occurred:", error.response);
    });
  }
 


  function updateProfileImage(){
    console.log(file)

    const data = new FormData();
    data.append('body', 'Test');

    data.append('files', file)
 

   
    
    axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/upload`, data,  config)
    .then((response) => {
     console.log("SUCCESS RESPONSE", response)
    })
    .catch((error) => {
      console.log("An error occurred:", error.response);
    });


    // const body = {
    //   username: 'david', 
    // };

   
  }


  return (
    <div className="App">
      <Layout >
<div className="max-w-5xl mx-auto">
      <h1 className="font-semibold text-3xl">{user.first_name} {user.last_name}</h1>
      <p>@{user.username}</p>
    
      <p>{user.bio}</p>

       <button onClick={()=>updateProfile()}>UPDATE</button>

       <input type="file" name="files" onChange={(e)=>console.log(setFile(e.target.files[0]))} />


       <button className="bg-gray-300 p-4 rounded-xl block " onClick={()=>updateProfileImage()}>Update Profile Image with URL</button>
        </div>
      </Layout>
    </div>
  );
}

export default EditProfile;
