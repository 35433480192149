import React, { useState, useRef, useEffect } from "react";
import LogoIcon from "../components/logo/icon";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = `KrasniyLand Privacy Policy`;
  }, []);

  return (
    <>
      <div
        className="relative "
        style={{ background: "linear-gradient(0, #fff, #0874d9)" }}
      >
        <div className="relative z-10 ">
          <div className="max-w-5xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <div className="w-16 p-2">
              <LogoIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-5xl shadow-xl rounded-xl bg-white mx-auto  px-4 py-12 sm:px-6   lg:px-8  text-gray-800 mb-12">
        <h1 className="font-semibold text-3xl">Privacy Policy</h1>
        <p className="leading-6">
          This policy describes the collection, use, and disclosure of Personal
          Information if anyone decided to use my KrasniyLand.
        </p>
        <div className="py-4">
          <h2 className="font-semibold text-lg text-gray-900">
            What kinds of information do we collect?
          </h2>
          <p>
            If you choose to use KrasniyLand, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that I collect is used for providing and improving
            KrasniyLand. I will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>{" "}
          <p>
            For a better experience, while using KrasniyLand, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name and email address.
          </p>
          <h2 className="font-semibold text-lg text-gray-900 mt-4">Log Data</h2>
          <p>
            In a case of an error in the app, Log Data may be collected. This
            Log Data may include information such as your device Internet
            Protocol (“IP”) address, device name, operating system version, the
            configuration of the app when utilizing KrasniyLand, the time and
            date of your use, and other statistics.
          </p>
          <h2 className="font-semibold text-lg text-gray-900 mt-4">Security</h2>
          <p>
            I value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and I
            cannot guarantee its absolute security.
          </p>
          <h2 className="font-semibold text-lg text-gray-900 mt-4">
            Children’s Privacy
          </h2>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            me with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact
            KrasniyLand.
          </p>
          <h2 className="font-semibold text-lg text-gray-900 mt-4">
            Changes to This Privacy Policy
          </h2>
          <p>
            The Privacy Policy may change from time to time. Thus, you are
            advised to review this page periodically for any changes.
          </p>{" "}
          <p>This policy is effective as of July 25, 2020</p>
          <h2 className="font-semibold text-lg text-gray-900 mt-4">
            Contact Us
          </h2>
          <p>
            If you have any questions or suggestions about my Privacy Policy, do
            not hesitate to contact me at support@krasniyland.com.
          </p>
          <p>Date of Last Revision: July 25, 2020</p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
