import React, { useEffect } from "react";
import {  withApollo } from "react-apollo";
import {  withRouter } from "react-router-dom";

function Logout(props) {
  console.log("conte", props);
  useEffect(() => {
    window.localStorage.removeItem("krsnylandToken");
    window.localStorage.removeItem("user");

    props.client.resetStore();
    props.client.clearStore();

    window.location.href = "/";
  }, []);

  return <></>;
}
export default withApollo(withRouter(Logout));
