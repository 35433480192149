 
import jwt from 'jwt-decode' 

export const isLoggedIn = () => {
    let token = null
    token = localStorage.getItem('krsnylandToken');

 

    var current_time = Date.now() / 1000;
    // var decoded = jwt(token);
    // console.log("decodedee",decoded);


    if ( jwt.exp < current_time || !token) {
        //expired
        console.log("logged out")
        return false

    } else if(!token) {
        console.log("logged out")

        return false
    } else {
        console.log("logged TRUE")

        return true
    }

 
}


