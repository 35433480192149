import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import { token, config } from "../../utils/api";
import SinglePost from "../../components/post";
import { NavLink } from "react-router-dom";
const reactStringReplace = require('react-string-replace');


const SinglePostDetails = ({ match, location }) => {
    
  const {
    params: { postId },
  } = match;

  const [loading, setLoading] = useState(true);

  const [me, setMe] = useState();
  const [post, setPost] = useState([]);
  const [postLikes, setPostLikes] = useState();
   
  const [fetchMessage, setFetchMessage] = useState(null);


  useEffect(() => {
    getMe();
    getPost();
    getPostLikes()
 
  
  }, [postId]);

 


  async function getMe() {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, config)
      .then((response) => {
        // Handle success.
        setMe(response.data); 
      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }
  async function getPost() {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/posts?id=${postId}`,
        config
      )
      .then((response) => {
        // Handle success.
        setPost(response.data[0]);
 
      })
      .catch((error) => {
        // Handle error.
        setFetchMessage("Post does not exist or has been deleted.")
        console.log("An error occurred:", error.response);
      });
  }
 
  async function getPostLikes() {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/likes?post=${postId}`,
        config
      )
      .then((response) => {
        // Handle success.
        setPostLikes(response.data);
 
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
      });
  }
 


  function refetchPost(){
    console.log("refetch")
}


if(fetchMessage) {
    return (
        <Layout>
            <p>{fetchMessage}</p>
        </Layout>
    )
}
console.log("POST",postLikes)
  return (
    <div className="App">
      <Layout>
      <div className="max-w-6xl mx-auto">

      <div className="py-5 px-4 sm:px-6 lg:px-8 bg-white max-w-6xl">
 
       {post && post.user && post.user.last_name && me && me.id ? 
         

 <SinglePost  
        me={me.id}
        id={post.id}
        item={post}
        authorUsername={post.user.username} 
        verified={post.user.verified} 

        firstName={post.user.first_name} 
        lastName={post.user.last_name} 
        date={post.created_at}
        postType={post.post_type}
        postContent={post.post_content}
        
        comments={post.comments}
        likes={post.likes}
        refetchPost={refetchPost()}
        
        /> 
       
: 'loadinng'}
<div className="px-2">
    
{postLikes && postLikes.length > 0 ? <div>
<span className="font-semibold text-black">{postLikes.length} like{postLikes.length == 1 ? '' :'s'}</span> by 
{postLikes && postLikes.length > 0 && postLikes.map((item,i)=> (
    <span key={i}>{i > 0 ? ',' : ''} {item.liked_by.username}</span>
))}

</div> : 'No likes'}

</div>


          </div>
          </div>
      </Layout>
    </div>
  );
};

export default SinglePostDetails;
