import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import { token, config } from "../../utils/api";
import SinglePost from "../../components/post";
import { NavLink } from "react-router-dom";

const FollowersList = ({ match, location }) => {
  const {
    params: { username },
  } = match;

  const [loading, setLoading] = useState("");

  const [user, setUser] = useState([]);

  const [userFollowers, setUserFollowers] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/users?username=${username}`,
        config
      )
      .then((response) => {
        console.log(response.data);

        setUser(response.data[0]);
        getFollowersList(response.data[0].id);
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  }

  function getFollowersList(id) {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/followings?following_user=${id}`,
        config
      )
      .then((response) => {
        // Handle success.
        setUserFollowers(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  }

  return (
    <div className="App">
      <Layout>
      <div className="py-5 px-4 sm:px-6 lg:px-8 bg-white max-w-6xl">
          <h1 className="font-semibold text-2xl">
            {user.first_name} {user.last_name} <span className="text-gray-600">Followers</span>
          </h1>
           

          {userFollowers.map((item, i) => {
            return <NavLink to={`/u/${item.user.username}`} key={i} className="bg-gray-100 rounded-xl px-6 py-4 my-4 flex items-center">{item.user.username} {item.user.verified ? <svg className="-mt-1 h-4 ml-1 text-blue-500 w-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="badge-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 256c0-37.7-23.7-69.9-57.1-82.4 14.7-32.4 8.8-71.9-17.9-98.6-26.7-26.7-66.2-32.6-98.6-17.9C325.9 23.7 293.7 0 256 0s-69.9 23.7-82.4 57.1c-32.4-14.7-72-8.8-98.6 17.9-26.7 26.7-32.6 66.2-17.9 98.6C23.7 186.1 0 218.3 0 256s23.7 69.9 57.1 82.4c-14.7 32.4-8.8 72 17.9 98.6 26.6 26.6 66.1 32.7 98.6 17.9 12.5 33.3 44.7 57.1 82.4 57.1s69.9-23.7 82.4-57.1c32.6 14.8 72 8.7 98.6-17.9 26.7-26.7 32.6-66.2 17.9-98.6 33.4-12.5 57.1-44.7 57.1-82.4zm-144.8-44.25L236.16 341.74c-4.31 4.28-11.28 4.25-15.55-.06l-75.72-76.33c-4.28-4.31-4.25-11.28.06-15.56l26.03-25.82c4.31-4.28 11.28-4.25 15.56.06l42.15 42.49 97.2-96.42c4.31-4.28 11.28-4.25 15.55.06l25.82 26.03c4.28 4.32 4.26 11.29-.06 15.56z" class=""></path></svg> : ""}</NavLink>;
          })}
        </div>
      </Layout>
    </div>
  );
};

export default FollowersList;
