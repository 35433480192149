import React, {useState,useRef,useEffect} from "react"; 
import { token, config } from "../utils/api";
import axios from "axios";
import { Link, NavLink,withRouter } from "react-router-dom"; 
import LogoIcon from './logo/icon.js'



function Layout(props) {

 

  const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false)
  const [ searchValue, setSearchValue ] = useState('')

  const [ searchResults, setSearchResults ] = useState([])

  const [me, setMe] = useState();

  useEffect(() => {
 
    getMe();
 
  }, []);


  async function getMe() {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, config)
      .then((response) => {
        // Handle success.
        setMe(response.data); 

        window.localStorage.setItem(
          "user",
          JSON.stringify({id: response.data.user.id,  username: response.data.user.username, first_name: response.data.first_name })
        );
  

      })
      .catch((error) => {
        // Handle error.

        console.log("An error occurred:", error.response);
      });
  }


 
  function searchTerm(tern) {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/users?_q=${tern}`,
        config
      )
      .then((response) => {
        // Handle success.
       
        setSearchResults(response.data);
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  }

  function handleSearchValueChange(e) {
 
    setSearchValue(e.target.value)
    searchTerm(e.target.value)
  }

  function handleSearchResultClick(e) {
    console.log(props)
 
    setSearchValue('')
    setSearchResults([]);

    props.history.push(e)

  }
 




  return (
    <>
  
               <div className="relative " style={{'background':'linear-gradient(0, #fff, #0874d9)'}}>
      <div className="relative z-10 ">
        <div className="max-w-6xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">

          <div className="w-16 p-2">
           <NavLink to={'/'}><LogoIcon/></NavLink>
          </div>

       
        
          <div className="relative w-full">
            <input type="text" value={searchValue} placeHolder={'Search anything'} onChange={(e)=>handleSearchValueChange(e)} className="transition ease-in duration-150 w-full xl:w-auto p-3 rounded-full  focus:outline-none focus:bg-white search-input" />
           {searchResults.length > 0 ? <div className="z-50 absolute bg-white mt-4 p-4 shadow-xl top-auto w-full rounded-lg">{searchResults.map((item,i)=>(
             <button type="button" onClick={(e)=>handleSearchResultClick(`/u/${item.username}`)}   key={i} className="text-left block my-2 bg-gray-100 rounded-xl p-3 px-8 hover:bg-gray-200">    <span className="font-semibold text-base flex items-center">
             {item.first_name} {item.last_name} {item.verified ? <svg className="text-blue-500 w-4 h-4 ml-1 -mt-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="badge-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 256c0-37.7-23.7-69.9-57.1-82.4 14.7-32.4 8.8-71.9-17.9-98.6-26.7-26.7-66.2-32.6-98.6-17.9C325.9 23.7 293.7 0 256 0s-69.9 23.7-82.4 57.1c-32.4-14.7-72-8.8-98.6 17.9-26.7 26.7-32.6 66.2-17.9 98.6C23.7 186.1 0 218.3 0 256s23.7 69.9 57.1 82.4c-14.7 32.4-8.8 72 17.9 98.6 26.6 26.6 66.1 32.7 98.6 17.9 12.5 33.3 44.7 57.1 82.4 57.1s69.9-23.7 82.4-57.1c32.6 14.8 72 8.7 98.6-17.9 26.7-26.7 32.6-66.2 17.9-98.6 33.4-12.5 57.1-44.7 57.1-82.4zm-144.8-44.25L236.16 341.74c-4.31 4.28-11.28 4.25-15.55-.06l-75.72-76.33c-4.28-4.31-4.25-11.28.06-15.56l26.03-25.82c4.31-4.28 11.28-4.25 15.56.06l42.15 42.49 97.2-96.42c4.31-4.28 11.28-4.25 15.55.06l25.82 26.03c4.28 4.32 4.26 11.29-.06 15.56z" class=""></path></svg> : ""}
           </span> <span className="block text-sm text-gray-600">@{item.username}</span></button>
           ))}</div> : null}
          </div>
 
      


        </div>
      </div>


      <div className={`absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden duration-200 ease-out ${mobileMenuOpen ? 'opacity-100 scale-100 pointer-events-auto duration-100 ease-in' : 'opacity-0 scale-95 pointer-events-none'}`}>
    <div className="rounded-lg shadow-lg">
      <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5 space-y-6 sm:space-y-8 sm:pb-8">
          <div className="flex items-center justify-between">
            <div>
              <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg" alt="Workflow"/>
            </div>
            <div className="-mr-2">
              <button onClick={()=>setMobileMenuOpen(false)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <nav className="space-y-8">
              <div className="grid gap-2 sm:grid-cols-2 sm:row-gap-8 sm:col-gap-4">
                <a href="#" className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                  </div>
                  <div className="text-base leading-6 font-medium text-gray-900">
                    Analytics
                  </div>
                </a>
                <a href="#" className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                    </svg>
                  </div>
                  <div className="text-base leading-6 font-medium text-gray-900">
                    Engagement
                  </div>
                </a>
                <a href="#" className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                  <div className="text-base leading-6 font-medium text-gray-900">
                    Security
                  </div>
                </a>
                <a href="#" className="-m-3 space-x-4 flex items-center p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                    </svg>
                  </div>
                  <div className="text-base leading-6 font-medium text-gray-900">
                    Integrations
                  </div>
                </a>
              </div>
              <div className="text-base leading-6">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                  View all products &rarr;
                </a>
              </div>
            </nav>
          </div>
        </div>
        <div className="py-6 px-5 space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <a href="#" className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
              Pricing
            </a>
            <a href="#" className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
              Docs
            </a>
            <a href="#" className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150">
              Company
            </a>
            <a href="#" className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-600 transition ease-in-out duration-150">
              Resources
            </a>
            <a href="#" className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-600 transition ease-in-out duration-150">
              Blog
            </a>
            <a href="#" className="rounded-md text-base leading-6 font-medium text-gray-900 hover:text-gray-600 transition ease-in-out duration-150">
              Contact Sales
            </a>
          </div>
          <div className="space-y-6">
            <span className="w-full flex rounded-md shadow-sm">
              <a href="#" className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                Sign up
              </a>
            </span>
            <p className="text-center text-base leading-6 font-medium text-gray-500">
              Existing customer?
              <a href="#" className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

               </div>
         {props.children}
         <div className="fixed bottom-0 w-full left-0 bg-white p-1 shadow-2xl border-t border-gray-100 pb-4 z-10">
            <div className="max-w-6xl mx-auto flex justify-around items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
              <NavLink to={'/home'} activeClassName="text-blue-600" className="text-sm text-center font-semibold flex flex-col justify-center items-center" ><svg className="w-8 h-8 p-1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>Home</NavLink>
              <NavLink to={`/u/${me && me.username}`} activeClassName="text-blue-600" className="text-sm text-center text-gray-600 font-semibold flex flex-col justify-center items-center" ><svg  className="w-8 h-8 p-1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>{JSON.parse(localStorage.getItem("user")).name}</NavLink>

            </div>
            
            
            </div>
            <div className="mb-40 text-center"><span className="text-gray-400 text-center block"></span></div>
</>
  );
}

export default withRouter(Layout);
  //  <div>
  //     {" "}
  //     <div className="w-1/3"><Logo/></div>
  //     <h1>
  //       Hi, {JSON.parse(localStorage.getItem("user")).name} -{" "}
  //       {JSON.parse(localStorage.getItem("user")).email}
  //     </h1>
  //     <Link to={"/register"}>Register</Link> <Link to={"/users"}>Users</Link>
  //     <Link to={"/dashboard"}>Dashboard</Link>{" "}
  //     <Link to={"/account"}>Account</Link>
  //     <Link to={"/logout"}>Signout</Link>
  //     <hr />{" "}
  //   </div>