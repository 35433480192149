import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter, NavLink } from "react-router-dom";
import LogoIcon from "./logo/icon";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null)
const [signingInInProgress,setSigningInInProgress] = useState(false)


  useEffect(() => {
 
    document.title = `Login to KrasniyLand`;
  });

  async function LoginUser(e) {
    e.preventDefault();
    setSigningInInProgress(true)
 
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/local`,
        {
          identifier: username.toLowerCase(),
          password: password,
        }
      );
      window.localStorage.setItem("krsnylandToken", data.jwt);
      window.localStorage.setItem(
        "user",
        JSON.stringify({ id: data.user.id, name: data.user.username, email: data.user.email })
      );

      window.location.reload();
      setTimeout(function(){ 
        
        setSigningInInProgress(false)
      }, 1000);

    
    } catch (e) { 
      setSigningInInProgress(false)
      setErrors(e.response && e.response.data && e.response.data.message[0])
    }
  }

  const ErrorMessages = () => {
    console.log("Erors",errors)
    return (
      <div> 
        {errors && errors.messages.map((item,i)=> {
          return  <div className="mb-4 overflow-hidden rounded-lg shadow-xs">
          <div className="p-4">
            <div className="flex  items-center">
              <div className="flex-shrink-0">
                 
                <svg className="h-6 w-6 text-red-600 font-semibold"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>

              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm leading-5 font-medium text-red-600">
                  {item.message}
                </p>
                 
              </div>
               
            </div>
          </div>
        </div>
        })}
      
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="mx-auto w-20"><LogoIcon/></div>
      <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
        Sign in to your KrasniyLand account
      </h2>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        Or 
        <NavLink to={'/register'} className="pl-1 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
           create a new account
        </NavLink>
      </p>
    </div>
  

     

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 sm:shadow sm:rounded-lg sm:px-10">
          {errors ? <ErrorMessages/> : null}
      <form onSubmit={(e) => LoginUser(e)}>
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-700">
              Username
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="username" type="username" required   value={username}      onChange={(e) => setUsername(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            </div>
          </div>
  
          <div className="mt-6">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Password
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="password" type="password" required value={password} onChange={(e) => setPassword(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
            </div>
          </div>
  
          <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center">
              <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"/>
              <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                Remember me
              </label>
            </div>
{/*   
            <div className="text-sm leading-5">
              <a href="#" className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                Forgot your password?
              </a>
            </div> */}
          </div>
  
          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                {signingInInProgress ? <div className="flex items-center"><div className="spinner-icon border-white w-4 h-4 border-2 mr-2"></div> Signing in</div> : 'Sign in'}
              </button>
            </span>
          </div>
        </form>
  

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm leading-5">
              <span className="px-2 bg-white text-gray-500">
               If you forgot your password, too bad.
              </span>
            </div>
          </div>
  
          
        </div>
      </div>
    </div>
  </div>
  

  );
}
export default withRouter(Login);
 