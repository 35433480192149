import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../../views/home/home.js";
import PublicHomePage from '../../views/home/publicHomepage'
import Login from "../login";
import Logout from "../logout";
import Register from "../register";
 
import UserProfile from '../../views/user/profile'
import FollowersList from '../../views/user/followers-list'
import FollowingList from '../../views/user/following-list'
import EditProfile from '../../views/user/edit-profile'
import SinglePostDetails from '../../views/post/single-post'
import PrivacyPolicy from '../../views/privacy-policy'
import Support from '../../views/support'
import Terms from '../../views/terms'
import { isLoggedIn } from "./isLoggedIn";
 

const AppRouter = () => (
  <Switch>
     <Route path="/" exact>
      {isLoggedIn() ? <Redirect to="/home" /> :  <Route path="/" exact component={PublicHomePage}/> }
    </Route>

    <Route path="/privacy-policy" exact>
   <PrivacyPolicy/>
    </Route>

    <Route path="/support" exact>
   <Support/>
    </Route>
     
    <Route path="/terms" exact>
   <Terms/>
    </Route>
    

    <Route path="/signin" exact>
      {isLoggedIn() ? <Redirect to="/home" /> : <Route path="/signin" exact component={Login}/>}
    </Route>

    <Route path="/register" exact>
      {isLoggedIn() ? <Redirect to="/home" /> : <Route path="/register" exact component={Register}/>}
    </Route>


    {isLoggedIn() ? <Route path="/u/:username" exact component={UserProfile}/> : <Route><Redirect to="/" /></Route>}
    {isLoggedIn() ? <Route path="/u/:username/followers" exact component={FollowersList}/> : <Route><Redirect to="/" /></Route>}
    {isLoggedIn() ? <Route path="/u/:username/following" exact component={FollowingList}/> : <Route><Redirect to="/" /></Route>}


    {isLoggedIn() ? <Route path="/post/:postId" exact component={SinglePostDetails}/> : <Route><Redirect to="/" /></Route>}

    


    {isLoggedIn() ? <Route path="/settings/profile" exact component={EditProfile}/> : <Route><Redirect to="/" /></Route>}


    
 
    {isLoggedIn() ? <Route path="/home" exact component={Home}/> : <Route><Redirect to="/" /></Route>}

   
      
    <Route path="/logout" exact>
      <Logout />
    </Route>

   


    <Redirect from='*' to='/' />


  </Switch>
);

export default AppRouter;
