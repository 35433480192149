import React, {useState} from 'react'
import axios from 'axios';
import { Route, withRouter, NavLink } from "react-router-dom";
 
import LogoIcon from "./logo/icon";

export default function Register(props) {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState(null)

    const [creatingAccountInProgress, setCreatingAccountInProgress] = useState(false)


  
        async function RegisterUser(e) {
 
            setCreatingAccountInProgress(true)
 
            e.preventDefault();
         
            axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/auth/local/register`, {
              username: username,
              email: email,
              password: password,
              first_name: firstName, 
              last_name: lastName, 
            })
            .then(response => {
              // Handle success.
              console.log('Well done!');
              console.log('User profile', response.data.user);
              console.log('User token', response.data.jwt);

              window.localStorage.setItem('krsnylandToken',response.data.jwt)
    
              window.localStorage.setItem('user',JSON.stringify({ name: response.data.user.username, email:response.data.user.email }))
          

              setTimeout(function(){ 
                window.location.href = "/home";
                setCreatingAccountInProgress(false)
              
              }, 1000);



              

            })
            .catch(e => {
              setCreatingAccountInProgress(false)
              
              // Handle error.
              setErrors(e.response && e.response.data && e.response.data.message[0])
            });
        }
    

      

        const ErrorMessages = () => {
          console.log("Erors",errors)
          return (
            <div> 
              {errors && errors.messages.map((item,i)=> {
                return  <div className="mb-4 overflow-hidden rounded-lg shadow-xs">
                <div className="p-4">
                  <div className="flex  items-center">
                    <div className="flex-shrink-0">
                       
                      <svg className="h-6 w-6 text-red-600"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
      
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm leading-5 font-medium text-red-600">
                        {item.message}
                      </p>
                       
                    </div>
                     
                  </div>
                </div>
              </div>
              })}
            
            </div>
          )
        }

        function createUsernameController(value) {
          let sanitizedValue = value
            .replace(/[^\w\s]/gi, '')
            .replace(/\s/g, '')
            .toLowerCase();
          setUsername(sanitizedValue);
        }

        


      return (
      
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="mx-auto w-20"><LogoIcon/></div>
      <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
        Create an account
      </h2>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        Or 
        <NavLink to={'/'} className="pl-1 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
           Sign in to an existing account
        </NavLink>
      </p>
    </div>
  

     

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
      <div className="bg-white py-8 px-4 sm:shadow sm:rounded-lg sm:px-10">
          {errors ? <ErrorMessages/> : null}
          <form onSubmit={(e) => RegisterUser(e)} >

         
     
        <div >
          <div>
            <label htmlFor="First" className="block text-sm font-medium leading-5 text-gray-700 ">
              First Name
            </label>
            <div className="mt-1 ">
              <div className="max-w-lg rounded-md shadow-sm ">
             

                <input id="First" value={firstName} onChange={e=>setFirstName(e.target.value)} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="last" className="block text-sm font-medium leading-5 text-gray-700 ">
              last Name
            </label>
            <div className="mt-1 ">
              <div className="max-w-lg rounded-md shadow-sm ">
             

                <input id="last" value={lastName} onChange={e=>setLastName(e.target.value)} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>
          </div>


         
          <div className="mt-6">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
              Email address
            </label>
            <div className="mt-1">
              <div className="max-w-lg rounded-md shadow-sm">
        
                <input id="email" type="email" value={email} onChange={e=>setEmail(e.target.value)} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>
          </div>

          <div className="mt-6 sm:mt-5">
          <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
              Username
            </label>
            <div className="mt-1">
              <div className="max-w-lg rounded-md shadow-sm">
             

                <input id="username" value={username} onChange={e=>createUsernameController(e.target.value)} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>
          </div>


          <div className="mt-6">
          <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px">
              Password
            </label>
            <div className="mt-1 ">
              <div className="max-w-lg rounded-md shadow-sm">
             

                <input id="password" value={password} type="password" onChange={e=>setPassword(e.target.value)} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
              </div>
            </div>
          </div>

 
       
        
        </div>
  
        <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button type="submit" className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out ${creatingAccountInProgress ? 'opacity-75'  : ''}`}>
                {creatingAccountInProgress ? 'Creating account...' : 'Create account'}
              </button>
            </span>
          </div>
  
          </form>
       
      </div>
    </div>
  </div>)
  
  
  
}