import React,{useState, useEffect} from "react";
import axios from "axios";  
import Layout from "../../components/layout";
import { config} from '../../utils/api'
import SinglePost from '../../components/post'

function Home() {
  const [postType, setPostType] = useState(1) 

  const [postContent, setPostContent] = useState(''); 
  const [loading, setLoading] = useState(''); 



  const [postFeed, setPostFeed] = useState([]) 

  const [me, setMe] = useState([]) 


  useEffect(() => {
    getPosts(); 
    getMe();
 
  }, []);


 


  async function getMe() {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/users/me`, config)
      .then((response) => {
        // Handle success.
        setMe(response.data);  

      })
      .catch((error) => { 
        console.log("An error occurred:", error.response);
      });
  }


  async function getPosts() {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/posts-home/`,config)
      .then((response) => {
        // Handle success. 
 console.log('rep',response.data)
 setPostFeed(response.data)
      })
      .catch((error) => {
        // Handle error.
    
        console.log("An error occurred:", error.response);
      });
  }


  async function createPost(e) {
    e.preventDefault()
    setLoading(true);
    
    const body = {
      post_type: postType,
      post_content: postContent,
     
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/posts`, body, config)
      .then((response) => {
        // Handle success.
  
        setPostContent('')
        setLoading(false);
 
        let postfeedlocal = [...postFeed]
        postfeedlocal.push({meId: response.data && response.data.user && response.data.user.id, ...response.data})
        setPostFeed(postfeedlocal)

      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
      });
  }

 
  return (
    <div className="App">
      <Layout >
   


<div className="max-w-6xl mx-auto">
      <form onSubmit={(e) => createPost(e)}>

            <div className="py-5 px-4 sm:px-6 lg:px-8 bg-white max-w-6xl">
           

                <div className="relative">
                  <label htmlFor="postContent" className="text-sm font-medium leading-5 text-gray-700 sr-only">New post</label>
                  <textarea rows="6" id="postContent" value={postContent} onChange={(e)=>setPostContent(e.target.value)} className="bg-gray-100 mt-1 block w-full p-4 rounded-xl  focus:outline-none focus:bg-gray-200 hover:bg-gray-200 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5"/>
                <div className="absolute bottom-0 left-0 w-full px-4 pb-2 flex justify-between items-baseline">
                  <span className="text-gray-400">{postContent.length}/140</span>
                <button type="submit" disabled={postContent.length < 1} className={`${postContent.length < 1 ? 'opacity-50' : ''} bottom-0 right-0 py-2 px-6 -ml-1 border-transparent text-sm leading-5 font-semibold rounded-xl text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out`}>
               {loading ? "Loading.." : "Post"} 
              </button>
                </div>
                 

                </div> 
 
              </div> 
        </form>

        <div className="py-5 px-4 sm:px-6 lg:px-8 bg-white max-w-6xl">
           
        {postFeed && postFeed.sort((a, b) => a.id < b.id ? 1 : -1).map((item, i) => {
          return (
            <SinglePost key={i}   
            me={item.meId}
            id={item.id}
            item={item}
            authorUsername={item.user.username} 
            verified={item.user.verified} 

            firstName={item.user.first_name} 
            lastName={item.user.last_name} 
            date={item.created_at}
            postType={item.post_type}
            postContent={item.post_content}
            
            comments={item.comments}
            likes={item.likes}
            
            /> 
       
          )
        })}
        </div>  
        
        </div> 
      </Layout>
    </div>
  );
}

export default Home;
