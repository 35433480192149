


import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = false
  if(localStorage.getItem('krsnylandToken')) {
      token = localStorage.getItem('krsnylandToken')
  } 
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
     authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;